import React, {
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { authService } from '../services/AuthService';
import { User } from '../../scenes/authentication/types/types.auth';

export type UserContextType = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  reload: number;
  setReload: Dispatch<SetStateAction<number>>;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
  reload: 0,
  setReload: () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [reload, setReload] = useState<number>(0);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        if (currentUser) {
          setUser(currentUser);
        }
      } catch (error) {
        console.error('Error loading user:', error);
      }
    };

    loadUser();
  }, [reload]);

  return (
    <UserContext.Provider value={{ user, setUser, reload, setReload }}>
      {children}
    </UserContext.Provider>
  );
};

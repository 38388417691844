import Divider from '@mui/material/Divider';
import { useContext } from 'react';
import styled from 'styled-components';
import StaticTextCard from '../../../shared/components/StaticTextCard';
import { UserContext } from '../../../shared/contexts/UserContext';
import { BasicReview } from '../../../shared/domain/review';
import { H4 } from '../../../shared/styles';
import CompanyProfileRating from './CompanyProfileReview';

type CompanyProfileReviewsProps = {
  reviews: Array<BasicReview>;
  title: string;
  handleOpenReviewDialog: (reviewId?: number, isEdit?: boolean) => void;
};

const StyledHeader = styled(H4)`
  text-decoration: underline;
  margin: var(--spacing-md) 0;
`;

const Wrapper = styled.div`
  display: flex;
  margin-left: var(--spacing-lg);
  justify-content: space-between;
  width: 100%;
`;

const CardWrapper = styled.div`
  flex-direction: column;
  width: 100%;
`;

const CompanyProfileReviews = (props: CompanyProfileReviewsProps) => {
  const { reviews, title, handleOpenReviewDialog } = props;
  const { user } = useContext(UserContext);

  return (
    <Wrapper>
      <CardWrapper>
        <StyledHeader>{title}</StyledHeader>
        <Divider sx={{ width: '100%' }} />
        &nbsp;
        {reviews.length === 0 && user ? (
          <StaticTextCard
            header="No Reviews Exist for this Company!"
            text="Please either leave a review, or come back at a later date."
            link={false}
            actionClick={() => handleOpenReviewDialog()}
            isCompanyProfile={true}
          />
        ) : reviews.length > 0 && user ? (
          reviews.map((review: BasicReview) => (
            <CompanyProfileRating
              review={review}
              key={review.id}
              handleOpenReviewDialog={handleOpenReviewDialog}
            />
          ))
        ) : (
          <StaticTextCard
            header="It seems you are not logged in!"
            text="Please either login in or register an account to see company reviews. "
            link={true}
          />
        )}
      </CardWrapper>
    </Wrapper>
  );
};

export default CompanyProfileReviews;

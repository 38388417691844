import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import {
  PurchaseDetailsReview,
  ReviewStep,
} from '../../../shared/domain/review';
import { useExtendState } from '../../../shared/utils/ExtendState';
import { PURCHASE_DETAILS_FORM_FIELDS } from '../data';
import { Container, StyledTextField } from '../styles/review.styles';
import ReviewFormButtons from './ReviewFormButtons';
import MultipleChoiceReviewQuestion, {
  QuestionWrapper,
  StyledQuestionText,
} from './MultipleChoiceReviewQuestion';

type PurchaseDetailsReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean,
    isViewMode?: boolean
  ) => void;
  currentReview: PurchaseDetailsReview;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
  isViewMode?: boolean;
};

const PurchaseDetailsReviewForm = (props: PurchaseDetailsReviewProps) => {
  const { submitReviewForm, loading, currentReview, setLoading, isViewMode } =
    props;
  const [formFields, extendFormFields, setFormFields] =
    useExtendState<PurchaseDetailsReview>(
      currentReview ?? ({} as PurchaseDetailsReview)
    );
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    const errors: any = {};

    const requiredFields = [
      'economicBuyerName',
      'championName',
      'championEffectiveness',
      'definedBudget',
      'salesCycleLength',
      'buyerFiscalYearEnd',
      'paymentCycle',
    ];

    requiredFields.forEach((field) => {
      //@ts-ignore
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const submitPurchaseDetailsReview = (e: any) => {
    e.preventDefault();
    const hasErrors = validate();

    if (hasErrors) {
      return;
    }
    submitReviewForm(
      e,
      formFields,
      ReviewStep.PURCHASE_DETAILS,
      true,
      isViewMode
    );
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as PurchaseDetailsReview));
    setLoading(false);
  }, [currentReview]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <form>
        <QuestionWrapper>
          <StyledQuestionText>
            What was the title of the economic buyer?
          </StyledQuestionText>
          <div style={{ width: '80%' }}>
            <StyledTextField
              name="economicBuyerName"
              value={formFields.economicBuyerName}
              onChange={(e) =>
                extendFormFields({ [e.target.name]: e.target.value })
              }
              size="small"
              InputProps={{
                readOnly: isViewMode,
              }}
            />
            {errors.economicBuyerName && (
              <ErrorMessage error={errors.economicBuyerName} />
            )}
          </div>
        </QuestionWrapper>

        <QuestionWrapper>
          <StyledQuestionText>
            What was the title of your champion?
          </StyledQuestionText>
          <div style={{ width: '80%' }}>
            <StyledTextField
              name="championName"
              onChange={(e) =>
                extendFormFields({ [e.target.name]: e.target.value })
              }
              value={formFields.championName}
              size="small"
              InputProps={{
                readOnly: isViewMode,
              }}
            />
            {errors.championName && (
              <ErrorMessage error={errors.championName} />
            )}
          </div>
        </QuestionWrapper>
        {PURCHASE_DETAILS_FORM_FIELDS.map((question) => (
          <MultipleChoiceReviewQuestion
            key={question.name}
            isViewMode={isViewMode}
            errors={errors}
            question={question}
            formFields={formFields}
            handleFormFieldChange={(e) =>
              extendFormFields({ [e.target.name]: e.target.value })
            }
            isNarrow={question.options.length > 8}
          />
        ))}
        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitPurchaseDetailsReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText="Move onto Evaluation"
          onSubmit={(e) => submitPurchaseDetailsReview(e)}
        />
      </form>
    </Container>
  );
};

export default PurchaseDetailsReviewForm;

import { Button } from '@mui/material';
import { Fragment } from 'react';
import styled from 'styled-components';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import { P, screenMdMin } from '../../../shared/styles';
import { MultipleChoiceQuestion } from '../../../shared/domain/form';

type MultipleChoiceQuestionProps = {
  handleFormFieldChange: (e: any) => void;
  formFields: any;
  question: MultipleChoiceQuestion;
  errors?: any;
  isNarrow?: boolean;
  isWide?: boolean;
  isViewMode?: boolean;
};

const MobileWrapper = styled.div`
  max-width: 90%;
  flex-direction: column;
  @media ${screenMdMin} {
    flex-direction: unset;
    max-width: 100%;
  }
`;

export const QuestionWrapper = styled.div`
  display: flex;
  margin: var(--spacing-md) 0;
  flex-wrap: wrap;
  flex-direction: column;
  @media ${screenMdMin} {
    flex-direction: unset;
  }
`;

const StyledAnswerButton = styled(Button)<{
  isActive?: boolean;
  isNarrow?: boolean;
}>`
  && {
    border: 1px solid var(--color-grey-dark);
    margin-right: var(--spacing-xs);
    margin-top: var(--spacing-xs);
    color: var(--color-black);
    text-transform: capitalize;
    width: ${({ isNarrow }) => (isNarrow ? '85px' : '130px')};
    height: 55px;
    font-size: 12px;
    background-color: ${({ isActive }) => (isActive ? '#78bbf1' : '')};
  }

  &&.Mui-disabled {
    opacity: 1;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const StyledQuestionText = styled(P)<{ isWide?: boolean }>`
  width: 100%;
  margin-bottom: var(--spacing-md);
  margin-top: var(--spacing-xs);
  font-size: 14px;
  @media ${screenMdMin} {
    margin-right: var(--spacing-md);
    margin-bottom: 0;
    width: ${({ isWide }) => (isWide ? '30%' : '15%')};
  }
`;

const MultipleChoiceReviewQuestion = (props: MultipleChoiceQuestionProps) => {
  const {
    handleFormFieldChange,
    formFields,
    question,
    isNarrow,
    isWide,
    errors,
    isViewMode,
  } = props;

  return (
    <QuestionWrapper>
      <StyledQuestionText isWide={isWide}>
        {question.question}
      </StyledQuestionText>
      <MobileWrapper>
        {question.options.map((value, i) => {
          return (
            <Fragment key={i}>
              <StyledAnswerButton
                isActive={formFields[question.name] === value}
                name={question.name}
                value={value}
                onClick={handleFormFieldChange}
                variant="outlined"
                isNarrow={isNarrow}
                disabled={isViewMode}
              >
                {value}
              </StyledAnswerButton>
            </Fragment>
          );
        })}
        {errors && errors[question.name] && (
          <ErrorMessage
            key={`${question.name}-error`}
            error={errors[question.name]}
          />
        )}
      </MobileWrapper>
    </QuestionWrapper>
  );
};

export default MultipleChoiceReviewQuestion;

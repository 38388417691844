import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Company } from '../../authentication/types/types.auth';
import styled from 'styled-components';
import { P } from '../../../shared/styles';
import ReviewRating from '../../review/components/ReviewRating';
import { Chip } from '@mui/material';
import { RATING_MAPPINGS } from '../../../shared/mapping/review';

type CompanyProfileAverageRatingProps = {
  company: Company;
};

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChipTextWrapper = styled.div`
  display: flex;
  margin-bottom: var(--spacing-md);
`;

const StyledChip = styled(Chip)`
  margin-left: var(--spacing-md);
`;

const StyledText = styled(P)`
  font-weight: 600;
  margin-top: var(--spacing-xs);
`;

const CompanyProfileAverageRating = (
  props: CompanyProfileAverageRatingProps
) => {
  const { company } = props;

  return (
    <Grid item xs={12} md={4}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          bgcolor: 'grey.200',
          marginBottom: 'var(--spacing-md)',
          width: '100%',
          height: '95%',
        }}
      >
        <ChipTextWrapper>
          <StyledText>No of Reviews:</StyledText>
          <StyledChip label={company.reviewsCount || 0} />
        </ChipTextWrapper>
        <ChipsContainer>
          {RATING_MAPPINGS.map((rating, i) => {
            const ratingValue = company[rating.responseName as keyof Company];
            const reviewsCount = company.reviewsCount || 1;
            const averageRating =
              typeof ratingValue === 'number' ? ratingValue / reviewsCount : 0;

            return (
              <ReviewRating
                key={i}
                value={averageRating.toString()}
                label={rating.shortLabel || rating.label}
                name={rating.name}
                isReadOnly={true}
              />
            );
          })}
        </ChipsContainer>
      </Paper>
    </Grid>
  );
};

export default CompanyProfileAverageRating;

import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled as MuiStyled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReviewStep } from '../../../shared/domain/review';
import { screenMdMin } from '../../../shared/styles';

const steps = [
  { label: 'Basic Info', id: ReviewStep.BASIC_REVIEW },
  { label: 'Purchase Details', id: ReviewStep.PURCHASE_DETAILS },
  { label: 'Evaluation', id: ReviewStep.EVALUATION },
  { label: 'Legal Review', id: ReviewStep.LEGAL },
  { label: 'Negotiation and Procurement', id: ReviewStep.NEGOTIATION },
];

type ReviewStepperProps = {
  step: number;
  handleBack: (step: ReviewStep) => void;
  rfp: boolean;
};

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.div`
  margin: var(--spacing-md) 0;
  @media ${screenMdMin} {
    margin: var(--spacing-md) -84px;
  }
`;

const ColorlibConnector = MuiStyled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(0,100, 200) 0%,rgb(0,100, 200) 50%,rgb(0,100, 200) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(0,100, 200) 0%,rgb(0,100, 200) 50%,rgb(0,100, 200) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = MuiStyled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  ...(ownerState.completed && {
    cursor: 'pointer',
  }),
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 95deg,rgb(0,100, 200) 0%,rgb(0,100, 200) 50%,rgb(0,100, 200) 100%)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 95deg,rgb(0,100, 200) 0%,rgb(0,100, 200) 50%,rgb(0,100, 200) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed || active ? (
        <StyledImage src={require('../../../assets/images/kandir_logo.png')} />
      ) : (
        <StyledImage
          src={require('../../../assets/images/kandir_logo_grey.jpg')}
        />
      )}
    </ColorlibStepIconRoot>
  );
}

const ReviewStepper = (props: ReviewStepperProps) => {
  const { step, handleBack, rfp } = props;
  const [customSteps, setCustomSteps] = useState(steps);

  useEffect(() => {
    if (rfp && !customSteps.some((step) => step.id === ReviewStep.RFP)) {
      setCustomSteps((prevSteps) => [
        ...prevSteps,
        { label: 'RFPs', id: ReviewStep.RFP },
      ]);
    } else if (!rfp && customSteps.some((step) => step.id === ReviewStep.RFP)) {
      const newSteps = customSteps.filter((step) => step.id !== ReviewStep.RFP);
      setCustomSteps(newSteps);
    }
  }, [rfp, customSteps]);

  return (
    <StyledContainer>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={<ColorlibConnector />}
      >
        {customSteps.map((stepItem, i) => (
          <Step
            key={stepItem.id}
            onClick={() => {
              if (i <= step) {
                handleBack(stepItem.id);
              }
            }}
            completed={i < step}
            disabled={i > step}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {stepItem.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </StyledContainer>
  );
};
export default ReviewStepper;

import { useEffect, useState, useCallback } from 'react';
import { mdDesktopScreen, mobileScreen } from '../styles/Breakpoints';

export const useResponsive = () => {
  const [width, setWidth] = useState<number | null>(null);

  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  }, [onResize]);

  return {
    isMobile: width !== null && width <= mobileScreen,
    isMdDesktop:
      width !== null && width > mobileScreen && width <= mdDesktopScreen,
  };
};

import React, { useState } from 'react';
import { Box, Button, LinearProgress, TextField } from '@mui/material';
import styled from 'styled-components';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../shared/components/Snackbar';
import { showErrorSnackbar } from '../../authentication/utils/auth.utils';
import { authService } from '../../../shared/services/AuthService';

const ButtonWrapper = styled.div`
  text-align: center;
`;

const StyledInput = styled(TextField)`
  margin-bottom: var(--spacing-md);
`;

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      const currentUser = await authService.getCurrentUser();
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }

      await authService.authenticateUser(currentUser.email, currentPassword);

      // Since the original CognitoUser.changePassword method is not directly accessible,
      // we'll need to implement this in the AuthService class
      await authService.changePassword(currentPassword, newPassword);

      setSnackbarConfig({
        message: 'Your password has been successfully changed!',
        open: true,
        type: 'success',
      });
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <StyledInput
        fullWidth
        label="Current Password"
        type="password"
        onChange={(e) => setCurrentPassword(e.target.value)}
        required
      />
      <StyledInput
        fullWidth
        type="password"
        label="New Password"
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />
      <ButtonWrapper>
        <Button
          type="submit"
          variant="contained"
          disabled={!currentPassword || !newPassword}
          onClick={onSubmit}
        >
          Change Password
        </Button>
      </ButtonWrapper>
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Box>
  );
};

export default ChangePassword;

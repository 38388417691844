import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { Textarea } from '../../../shared/components/TextArea';
import { LegalReview, ReviewStep } from '../../../shared/domain/review';
import { Container, StyledText } from '../styles/review.styles';
import ReviewFormButtons from './ReviewFormButtons';
import MultipleChoiceReviewQuestion from './MultipleChoiceReviewQuestion';
import { LEGAL_FORM_FIELDS } from '../data';

type LegalReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean,
    isViewMode?: boolean
  ) => void;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
  currentReview: LegalReview;
  isViewMode?: boolean;
};

const LegalReviewForm: React.FC<LegalReviewProps> = ({
  submitReviewForm,
  loading,
  setLoading,
  currentReview,
  isViewMode,
}) => {
  const [errors, setErrors] = useState<any>({});
  const [formFields, setFormFields] = useState<LegalReview>({} as LegalReview);

  const validate = () => {
    const errors: any = {};
    const requiredFields: (keyof LegalReview)[] = [
      'legalReviewLength',
      'legalReviewDifficulty',
      'notableGenComment',
      'marketingPermission',
      'autoRenewalClause',
      'customerAskLiability',
      'endAskLiability',
    ];

    requiredFields.forEach((field) => {
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const submitLegalReview = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasErrors = validate();

    if (hasErrors) {
      return;
    }

    submitReviewForm(e, formFields, ReviewStep.LEGAL, true, isViewMode);
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as LegalReview));
    setLoading(false);
  }, [currentReview, setLoading]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <form onSubmit={submitLegalReview}>
        {LEGAL_FORM_FIELDS.map((question) => (
          <MultipleChoiceReviewQuestion
            isViewMode={isViewMode}
            key={question.name}
            errors={errors}
            question={question}
            formFields={formFields}
            handleFormFieldChange={handleFormFieldChange}
            isNarrow={question.options.length > 8}
            isWide
          />
        ))}
        <Grid container>
          <Grid item xs={12} sm={6}>
            <StyledText>
              Were there any carve outs and/or supercaps to the
              LoL/Indemnification agreed upon? Please be specific?
            </StyledText>
            <Textarea
              style={{ resize: 'none' }}
              name="liabilityComment"
              onChange={handleFormFieldChange}
              value={formFields.liabilityComment}
              required
              minRows={4}
              readOnly={isViewMode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledText spaceleft>
              What were the legal team's main points of contention, and how did
              you mitigate/address them?
            </StyledText>
            <Textarea
              style={{ resize: 'none' }}
              name="notableGenComment"
              onChange={handleFormFieldChange}
              value={formFields.notableGenComment}
              minRows={4}
              required
              spaceleft
              readOnly={isViewMode}
            />
            <div style={{ marginLeft: '24px' }}>
              {errors.notableGenComment && (
                <ErrorMessage error={errors.notableGenComment} />
              )}
            </div>
          </Grid>
        </Grid>
        <ReviewFormButtons
          handleContinueReview={submitLegalReview}
          submitButtonText="Save Review as Draft"
          nextButtonText="Move onto Negotiation"
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            submitReviewForm(e, formFields, ReviewStep.LEGAL)
          }
        />
      </form>
    </Container>
  );
};

export default LegalReviewForm;

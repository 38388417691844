import styled from 'styled-components';
import { P, screenMdMin } from '../../../../shared/styles';
import { useResponsive } from '../../../../shared/hooks/useResponsive';

const Container = styled.div`
  width: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const BannerHeader = styled.h3<{ spacetop?: boolean }>`
  text-align: center;
  color: var(--color-white);
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: ${({ spacetop }) => (spacetop ? '28px' : '0')};
  font-size: 12px;

  @media ${screenMdMin} {
    font-size: 32px;
    top: 30%;
    margin-top: ${({ spacetop }) => (spacetop ? '45px' : '0px')};
  }
`;

const BannerText = styled(P)`
  font-size: 8px;
  text-align: center;
  color: var(--color-white);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${screenMdMin} {
    font-size: 32px;
    top: 45%;
  }
`;

const Banner = () => {
  const { isMobile } = useResponsive();
  return (
    <Container>
      <StyledImage
        src={require('../../../../assets/images/welcomePage/Hero banner.png')}
      />
      <BannerHeader>Bringing transparency to the opaque</BannerHeader>
      <BannerHeader spacetop>world of technology buying</BannerHeader>
      {!isMobile && (
        <BannerText>
          A community built entirely by sellers, exclusively for sellers.
        </BannerText>
      )}
    </Container>
  );
};

export default Banner;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { Textarea } from '../../../shared/components/TextArea';
import { NegotiationReview, ReviewStep } from '../../../shared/domain/review';
import { Container, StyledText } from '../styles/review.styles';
import ReviewFormButtons from './ReviewFormButtons';
import MultipleChoiceReviewQuestion from './MultipleChoiceReviewQuestion';
import { NEGOTIATION_FORM_FIELDS } from '../data';
import {
  MultipleChoiceQuestion,
  QuestionType,
  Question,
} from '../../../shared/domain/form';

type NegotiationReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean,
    isViewMode?: boolean
  ) => void;
  loading?: boolean;
  rfp: boolean;
  setLoading: (loading: boolean) => void;
  currentReview: NegotiationReview;
  isViewMode?: boolean;
  setOpen?: (isOpen: boolean) => void;
};

const NegotiationReviewForm = (props: NegotiationReviewProps) => {
  const {
    submitReviewForm,
    loading,
    currentReview,
    setLoading,
    rfp,
    isViewMode,
    setOpen,
  } = props;
  const [errors, setErrors] = useState<any>({});
  const [formFields, setFormFields] = useState<NegotiationReview>(
    currentReview ?? ({} as NegotiationReview)
  );

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const validate = () => {
    const errors: any = {};

    const requiredFields = [
      'negotiationTipsComment',
      'fairnessComment',
      'competitiveBids',
      'discountGiven',
      'fundsReleased',
      'noticeHonored',
      'noticeSelection',
      'procurementFairness',
      'procurementTactics',
      'procurementThreshold',
      'terminationConvenience',
      'forcedTerminationAcceptance',
    ];

    requiredFields.forEach((field) => {
      //@ts-ignore
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const submitNegotiationReview = (e: any) => {
    e.preventDefault();
    const hasErrors = validate();

    if (hasErrors) {
      return;
    }
    submitReviewForm(e, formFields, ReviewStep.NEGOTIATION, true, isViewMode);
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as NegotiationReview));
    setLoading(false);
  }, [currentReview]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <form>
        <Grid container spacing={2}>
          {NEGOTIATION_FORM_FIELDS.map((question, index) => {
            if (question.name === 'forcedTerminationAcceptanceComment') {
              return null;
            }

            const forceNewRow =
              question.ownRow ||
              (index > 0 && NEGOTIATION_FORM_FIELDS[index - 1].ownRow);

            return (
              <React.Fragment key={question.name}>
                {forceNewRow && <Grid item xs={12} />}

                <Grid item xs={12} sm={6}>
                  {question.type === QuestionType.MULTIPLE_CHOICE ? (
                    <MultipleChoiceReviewQuestion
                      isViewMode={isViewMode}
                      errors={errors}
                      question={question}
                      formFields={formFields}
                      handleFormFieldChange={(e) =>
                        setFormFields({
                          ...formFields,
                          [question.name]: e.currentTarget.value,
                        })
                      }
                      isNarrow={question.options.length > 3}
                      isWide
                    />
                  ) : question.type === QuestionType.TEXT ? (
                    <>
                      <StyledText>{question.question}</StyledText>
                      <Textarea
                        style={{ resize: 'none' }}
                        name={question.name}
                        value={String(
                          formFields[
                            question.name as keyof NegotiationReview
                          ] || ''
                        )}
                        onChange={(e) =>
                          setFormFields({
                            ...formFields,
                            [e.target.name]: e.target.value,
                          })
                        }
                        required
                        readOnly={isViewMode}
                        minRows={4}
                      />
                      {errors[question.name] && (
                        <ErrorMessage error={errors[question.name]} />
                      )}
                    </>
                  ) : null}
                </Grid>
                {question.name === 'forcedTerminationAcceptance' &&
                  formFields.terminationConvenience === 'Yes' &&
                  formFields.forcedTerminationAcceptance === 'No' && (
                    <>
                      {(question.ownRow || forceNewRow) && (
                        <Grid item xs={12} />
                      )}

                      <Grid item xs={12} sm={6}>
                        {(() => {
                          const commentQuestion = NEGOTIATION_FORM_FIELDS.find(
                            (q) =>
                              q.name === 'forcedTerminationAcceptanceComment'
                          );
                          if (!commentQuestion) return null;

                          return (
                            <>
                              <StyledText>
                                {commentQuestion.question}
                              </StyledText>
                              <Textarea
                                style={{ resize: 'none' }}
                                name="forcedTerminationAcceptanceComment"
                                value={
                                  formFields.forcedTerminationAcceptanceComment ||
                                  ''
                                }
                                onChange={(e) =>
                                  setFormFields({
                                    ...formFields,
                                    forcedTerminationAcceptanceComment:
                                      e.target.value,
                                  })
                                }
                                required
                                readOnly={isViewMode}
                                minRows={4}
                              />
                              {errors.forcedTerminationAcceptanceComment && (
                                <ErrorMessage
                                  error={
                                    errors.forcedTerminationAcceptanceComment
                                  }
                                />
                              )}
                            </>
                          );
                        })()}
                      </Grid>
                    </>
                  )}
              </React.Fragment>
            );
          })}
        </Grid>

        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitNegotiationReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText={
            rfp
              ? 'Move onto RFP'
              : isViewMode
                ? 'Done'
                : 'Submit Deep Dive Review'
          }
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? setOpen && setOpen(false)
              : submitReviewForm(e, formFields, ReviewStep.NEGOTIATION)
          }
        />
      </form>
    </Container>
  );
};

export default NegotiationReviewForm;

export enum QuestionType {
  CHECKBOX = 'checkbox',
  MULTIPLE_CHOICE = 'multiple-choice',
  TEXT = 'text',
  SHORT_TEXT = 'short-text',
  DATE = 'date',
  NUMBER = 'number',
  RATING = 'rating',
  TAG_INPUT = 'tag-input',
}

// Base interface for a question
export interface Question {
  name: string;
  type: QuestionType;
  question: string;
  required?: boolean;
  ownRow?: boolean;
}

export interface MultipleChoiceQuestion extends Question {
  type: QuestionType.MULTIPLE_CHOICE;
  options: string[];
}

export interface CheckboxQuestion extends Question {
  type: QuestionType.CHECKBOX;
  options: string[];
}
export interface TextQuestion extends Question {
  type: QuestionType.TEXT;
  maxLength?: number;
}

export interface RatingQuestion extends Question {
  type: QuestionType.RATING;
  min?: number;
  max?: number;
}

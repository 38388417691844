import PreLoginContainer from '../components/PreLoginContainer';
import { pricingCards } from './data';
import PricingTable from './pricing-table/PricingTable';

const Pricing = () => {
  return (
    <PreLoginContainer>
      <PricingTable pricingCards={pricingCards} />
    </PreLoginContainer>
  );
};

export default Pricing;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import styled from 'styled-components';
import {
  Breakpoints,
  H2,
  H4,
  screenLgMin,
  screenMdMin,
} from '../../shared/styles';
import { Company } from '../authentication/types/types.auth';
import CompanySearchInput from './components/CompanySearchInput';
import { UserContext } from '../../shared/contexts/UserContext';
import ReviewDialog from '../review/components/ReviewDialog';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import { FilterCompanyEnum } from './types/company.types';
import { FetchCompaniesParams, fetchCompanies } from './api/api';
import NewCompanyCard from './components/CompanyCard';
import SpinnerLoader from '../../shared/components/Loader';
import Pills from '../../shared/components/Pill';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  padding: var(--spacing-md) 0;

  @media ${screenLgMin} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${screenMdMin} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: ${Breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const NoResultWrapper = styled.div`
  text-align: center;
`;

const StyledNoResultHeader = styled(H4)`
  margin: var(--spacing-md) auto;
  align-items: center;
  text-align: center;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CompanySearchInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: var(--spacing-md);
`;

const PageWrapper = styled.div`
  padding: var(--spacing-md);
`;

const StyledHeader = styled(H2)`
  margin: var(--spacing-md) auto;
  align-items: center;
  text-align: center;
`;

const CompanySearchPage = () => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState([
    {
      id: 0,
      filterValue: FilterCompanyEnum.ALL,
      label: 'Alphabetical',
      isActive: true,
    },
    {
      id: 1,
      filterValue: FilterCompanyEnum.MOST_RECENT,
      label: 'Latest Reviews',
      isActive: false,
    },
    {
      id: 2,
      filterValue: FilterCompanyEnum.MOST_REVIEWED,
      label: 'Most Reviews',
      isActive: false,
    },
  ]);

  const { user } = useContext(UserContext);

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const cancelRating = () => {
    setSnackbarConfig({
      type: 'error',
      message: 'You must have engaged with this company to leave a review.',
      open: true,
    });
  };

  const ratingSuccess = () => {
    setSnackbarConfig({
      type: 'success',
      message:
        'Company and Review have been successfully submitted for approval.',
      open: true,
    });
  };

  const handleFilterClicks = (id: number) => {
    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        return {
          ...filter,
          isActive: filter.id === id,
        };
      });
    });
  };

  const getCompanies = async () => {
    try {
      setLoading(true);
      const searchTerms = {
        searchTerm: searchTerm,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/company/search`,
        searchTerms
      );
      if (Array.isArray(res.data)) {
        setCompanies(res.data);
      }
    } catch (error) {
      setCompanies([]);
    } finally {
      setLoading(false);
    }
  };

  const memoizedFetchCompanies = useMemo(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const filter = filters.find((filter) => filter.isActive === true);
        const params: FetchCompaniesParams = {
          filter: filter?.filterValue || FilterCompanyEnum.ALL,
        };

        const companies = await fetchCompanies(params);

        setCompanies(companies);

        setLoading(false);
      } catch (error) {
        setSnackbarConfig({
          open: true,
          type: 'error',
          message: 'Server error, please try again later',
        });
        setLoading(false);
      }
    };

    return fetchData;
  }, [user, filters, searchTerm]);

  const companyOptions = companies
    ?.filter((company: Company) => company.isApproved)
    .map((company) => {
      return {
        label: company.companyName,
      };
    });

  useEffect(() => {
    memoizedFetchCompanies();
  }, [filters]);

  useEffect(() => {
    getCompanies();
  }, [searchTerm, user]);

  return (
    <PageWrapper>
      <SearchWrapper>
        <StyledHeader>Search Companies</StyledHeader>
        <CompanySearchInputWrapper>
          <CompanySearchInput
            handleChange={setSearchTerm}
            options={companyOptions}
            label="Company Name"
          />
        </CompanySearchInputWrapper>
        {/* REMOVE BEFORE PROD */}
        {/* <Pills pills={filters} handleFilterClick={handleFilterClicks} /> */}
      </SearchWrapper>

      {loading ? (
        <SpinnerLoader />
      ) : companies?.length ? (
        <Container>
          {companies.map((company, index) => (
            <NewCompanyCard key={index} company={company} />
          ))}
        </Container>
      ) : (
        <NoResultWrapper>
          <StyledNoResultHeader>
            Don't see the company you're looking for?
          </StyledNoResultHeader>
          <Button
            variant="contained"
            onClick={() => {
              setIsAddCompany(true);
              setOpenReviewDialog(true);
            }}
          >
            Add a company to our database with a review
          </Button>
        </NoResultWrapper>
      )}

      <ReviewDialog
        open={openReviewDialog}
        isAddCompany={isAddCompany}
        setOpen={setOpenReviewDialog}
        cancelRating={cancelRating}
        ratingSuccess={ratingSuccess}
      />

      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </PageWrapper>
  );
};

export default CompanySearchPage;

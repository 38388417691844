import React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../shared/components/Container';
import LoadingProgress from '../../shared/components/LoadingProgress';
import Pills from '../../shared/components/Pill';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import { UserContext } from '../../shared/contexts/UserContext';
import { BasicReview } from '../../shared/domain/review';
import { H2 } from '../../shared/styles';
import { Company } from '../authentication/types/types.auth';
import CompanySearchInput from '../company-search/components/CompanySearchInput';
import ReviewDialog from '../review/components/ReviewDialog';
import OnboardingList from './components/OnboardingList';
import { ReviewFilter } from './types/welcome.types';
import { FetchReviewsParams, fetchReviews } from './utils/api';
import ReviewSection from './components/ReviewSection';

type WelcomePageProps = {
  setIsNavOpen: (isOpen: boolean) => void;
};

const StyledHeader = styled(H2)`
  text-align: center;
  margin-bottom: var(--spacing-md);
`;

const PillsWrapper = styled.div`
  margin-bottom: var(--spacing-md);
`;

const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SearchWrapper = styled.div`
  width: 80%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const WelcomePage = (props: WelcomePageProps) => {
  const { setIsNavOpen } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState([
    {
      id: 0,
      filterValue: ReviewFilter.LATEST,
      label: 'Latest Reviews',
      isActive: true,
    },
    {
      id: 1,
      filterValue: ReviewFilter.USER,
      label: 'My Reviews',
      isActive: false,
    },
  ]);
  const [reviews, setReviews] = useState<Array<BasicReview> | null>(null);
  const [companiesData, setCompaniesData] = useState<Array<Company>>([]);
  const [reviewsData, setReviewsData] = useState<Array<BasicReview> | null>(
    null
  );
  const [reviewTableRendered, setReviewTableRendered] = useState(false);
  const [reviewTableKey, setReviewTableKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const userVerified = searchParams.get('verified');
  const navigate = useNavigate();

  const handleFilterClicks = (id: number) => {
    setLoading(true);
    setReviewTableRendered(false);
    setReviewTableKey((prevKey) => prevKey + 1);
    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        return { ...filter, isActive: filter.id === id };
      });
    });
  };

  const handleReviewTableRendered = useCallback(() => {
    setReviewTableRendered(true);
  }, []);

  const closeReviewDialog = () => setOpenReviewDialog(false);

  const memoizedCompanies = useMemo(() => {
    if (companiesData) {
      setCompanies(companiesData);
      return companiesData;
    }
    return null;
  }, [companiesData]);

  const memoizedReviews = useMemo(() => {
    if (reviewsData) {
      setReviews(reviewsData);
      return reviewsData;
    }
    return null;
  }, [reviewsData]);

  const companyOptions =
    memoizedCompanies &&
    memoizedCompanies
      ?.filter((company: Company) => company.isApproved)
      .map((company) => {
        return {
          label: company.companyName,
        };
      });

  const ratingSuccess = () => {
    setOpenReviewDialog(false);
    setSnackbarConfig({
      type: 'success',
      message:
        'Company and Review have been successfully submitted for approval.',
      open: true,
    });
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const getCompanies = useCallback(async () => {
    try {
      const searchTerms = {
        searchTerm: searchTerm,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/company/search`,
        searchTerms
      );

      setCompaniesData(res.data);
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'Server error, please try again later',
      });
    }
  }, [searchTerm]);

  const getReviews = useCallback(async () => {
    setLoading(true);
    try {
      const filter = filters.find((filter) => filter.isActive === true);
      const params: FetchReviewsParams = {
        filter: filter?.filterValue || ReviewFilter.LATEST,
        token: user?.idToken || '',
        limit: 10,
      };

      if (filter?.filterValue === ReviewFilter.USER) {
        params.userId = user?.id;

        params.companyIds =
          user?.Companies &&
          user?.Companies.map((company: Company) => company.id).filter(
            (id): id is number => id !== undefined
          );
      }
      const reviewsResponse = await fetchReviews(params);

      const uniqueReviews = Array.from(
        new Set(reviewsResponse.map((review: { id: any }) => review.id))
      ).map((id) =>
        reviewsResponse.find((review: { id: unknown }) => review.id === id)
      );

      setReviewsData(uniqueReviews);
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'Server error, please try again later',
      });
    } finally {
      setLoading(false);
    }
  }, [filters, user]);

  useEffect(() => {
    if (userVerified === 'true') {
      setSnackbarConfig({
        message: 'Your account has been successfully verified!',
        open: true,
        type: 'success',
      });
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      getCompanies();
      getReviews();
    }
  }, [user, getReviews]);

  useEffect(() => {
    const companyNames = memoizedCompanies?.map(
      (company) => company.companyName
    );
    if (companyNames?.includes(searchTerm)) {
      navigate(`/company-search/${searchTerm}`);
    }
  }, [memoizedCompanies, navigate, searchTerm]);

  if (memoizedCompanies == null) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <SearchWrapper>
        {user &&
          user.onboardingConfig &&
          user?.onboardingConfig?.length > 0 && (
            <OnboardingList setSnackbarConfig={setSnackbarConfig} />
          )}
        <StyledHeader>Search Companies</StyledHeader>
        <SearchInputWrapper>
          <CompanySearchInput
            handleChange={setSearchTerm}
            options={companyOptions}
            label="Search Companies"
            handleNoOptionClick={() => setOpenReviewDialog(true)}
          />
        </SearchInputWrapper>

        <StyledHeader>My Review Feed</StyledHeader>
        <PillsWrapper>
          <Pills pills={filters} handleFilterClick={handleFilterClicks} />
        </PillsWrapper>

        {loading && <LoadingProgress />}

        <ReviewSection
          setIsNavOpen={setIsNavOpen}
          reviews={memoizedReviews}
          loading={loading}
          filterType={
            filters.find((filter) => filter.isActive)?.filterValue ||
            ReviewFilter.LATEST
          }
        />
      </SearchWrapper>

      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
      <ReviewDialog
        open={openReviewDialog}
        setOpen={setOpenReviewDialog}
        cancelRating={closeReviewDialog}
        ratingSuccess={ratingSuccess}
        isAddCompany={true}
      />
    </Container>
  );
};

WelcomePage.whyDidYouRender = true;

export default WelcomePage;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import axios from 'axios';
import dayjs from 'dayjs';
import { useContext } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Company } from '../../authentication/types/types.auth';
import { Button } from '@mui/material';
import { StyledLink } from '../../../shared/components/Link';
import { UserContext } from '../../../shared/contexts/UserContext';

type FollowCompanyTableProps = {
  data: Array<Company>;
  unFollowSuccess: (companyName: string) => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976D2',
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const FollowCompanyTable = (props: FollowCompanyTableProps) => {
  const { data, unFollowSuccess } = props;

  const { user, setReload, reload } = useContext(UserContext);

  const unFollowCompany = async (companyId: number, companyName: string) => {
    try {
      const updateCompanyInput = {
        companyId: companyId,
        userId: user?.id,
      };

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/unfollow-company`,
        updateCompanyInput,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setReload(reload + 1);
      unFollowSuccess(companyName);
    } catch (error) {}
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Company Profile</StyledTableCell>
            <StyledTableCell align="left">Date Followed</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: Company) => (
            <TableRow key={row.companyName}>
              <StyledTableCell component="th" scope="row">
                <StyledLink to={`/company-search/${row.companyName}`}>
                  {row.companyName}
                </StyledLink>
              </StyledTableCell>
              <StyledTableCell align="left">
                {/* @ts-ignore */}
                {dayjs(row.createdAt).format('MM/DD/YYYY') ||
                  dayjs(new Date()).format('MM/DD/YYYY')}
              </StyledTableCell>

              <StyledTableCell align="left">
                <Button
                  variant="contained"
                  onClick={() =>
                    unFollowCompany(row.id || 0, row.companyName || '')
                  }
                >
                  Unfollow
                </Button>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FollowCompanyTable;

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import axios from 'axios';
import { User } from '../../scenes/authentication/types/types.auth';

const UserPoolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
};

const userPool = new CognitoUserPool(UserPoolData);

class AuthService {
  private getCognitoUser(email: string): CognitoUser {
    return new CognitoUser({
      Username: email,
      Pool: userPool,
    });
  }

  private async fetchUserDetails(email: string, token: string) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/user/${email}`,
      { headers: { Authorization: token } }
    );
    return response.data;
  }

  private async buildUser(
    email: string,
    session: CognitoUserSession
  ): Promise<User> {
    const idToken = session.getIdToken().getJwtToken();
    const isAdmin =
      session.getIdToken().payload['cognito:groups']?.includes('admin') ||
      false;

    try {
      const userDetails = await this.fetchUserDetails(email, idToken);
      return {
        email,
        idToken,
        isAdmin,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        company: userDetails?.company,
        industry: userDetails.industry,
        positionTitle: userDetails.positionTitle,
        Companies: userDetails.Companies,
        id: userDetails?.id,
        hasCompletedRegistration: !!userDetails,
        createdAt: userDetails?.createdAt,
        reviews: userDetails?.reviews,
        companySize: userDetails?.companySize,
        onboardingConfig: userDetails?.onboardingConfig,
      };
    } catch (error) {
      console.error('Error fetching user details:', error);
      return { email, idToken, isAdmin, hasCompletedRegistration: false };
    }
  }

  public authenticateUser(
    email: string,
    password: string
  ): Promise<User | { newPasswordRequired: true }> {
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      const cognitoUser = this.getCognitoUser(email);
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async (session: CognitoUserSession) => {
          const user = await this.buildUser(email, session);
          resolve(user);
        },
        onFailure: (err) => reject(err),
        newPasswordRequired: () => resolve({ newPasswordRequired: true }),
      });
    });
  }

  public confirmRegistration(
    email: string,
    code: string,
    forceAliasCreation: boolean
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email);
      cognitoUser.confirmRegistration(
        code,
        forceAliasCreation,
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  public resendConfirmationCode(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email);
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(
          (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject(err);
            } else if (session) {
              cognitoUser.changePassword(
                oldPassword,
                newPassword,
                (err, result) => {
                  if (err) {
                    reject(err);
                  } else {
                    resolve();
                  }
                }
              );
            } else {
              reject(new Error('No active session'));
            }
          }
        );
      } else {
        reject(new Error('No authenticated user'));
      }
    });
  }

  public completeNewPasswordChallenge(
    email: string,
    newPassword: string,
    requiredAttributes: any
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email);
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        requiredAttributes,
        {
          onSuccess: (session: CognitoUserSession) => {
            resolve(this.buildUser(email, session));
          },
          onFailure: (err: any) => {
            reject(err);
          },
        }
      );
    });
  }

  public signUpUser(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      userPool.signUp(email, password, [], [], (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  public async getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(
          async (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject(err);
            } else if (session) {
              const idToken = session.getIdToken().getJwtToken();
              const isAdmin =
                session
                  .getIdToken()
                  .payload['cognito:groups']?.includes('admin') || false;

              cognitoUser.getUserAttributes((attrErr, attributes) => {
                if (attrErr) {
                  console.error('Error getting user attributes:', attrErr);
                  reject(attrErr);
                } else if (attributes) {
                  // Find the email attribute
                  const emailAttribute = attributes.find(
                    (attr) => attr.getName() === 'email'
                  );
                  const email = emailAttribute ? emailAttribute.getValue() : '';

                  if (email) {
                    this.fetchUserDetails(email, idToken)
                      .then((userDetails) => {
                        const user: User = {
                          email,
                          idToken,
                          isAdmin,
                          ...userDetails,
                          hasCompletedRegistration: !!userDetails,
                        };
                        resolve(user);
                      })
                      .catch((error) => {
                        console.error('Error fetching user details:', error);
                        resolve({
                          email,
                          idToken,
                          isAdmin,
                          hasCompletedRegistration: false,
                        });
                      });
                  } else {
                    reject(new Error('Email not found in user attributes'));
                  }
                } else {
                  reject(new Error('No user attributes found'));
                }
              });
            } else {
              resolve(null);
            }
          }
        );
      } else {
        resolve(null);
      }
    });
  }
}

export const authService = new AuthService();

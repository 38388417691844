import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'underscore';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { Textarea } from '../../../shared/components/TextArea';
import { UserContext } from '../../../shared/contexts/UserContext';
import {
  MultipleChoiceQuestion,
  Question,
  QuestionType,
} from '../../../shared/domain/form';
import {
  BasicReview,
  RatingAspect,
  ReviewDepth,
  ReviewStep,
} from '../../../shared/domain/review';
import { H5 } from '../../../shared/styles';
import { useExtendState } from '../../../shared/utils/ExtendState';
import { BASIC_REVIEW_FORM_FIELDS } from '../data';
import {
  Container,
  StyledSelectWrapper,
  StyledText,
  StyledTextField,
} from '../styles/review.styles';
import MultipleChoiceReviewQuestion, {
  QuestionWrapper,
  StyledQuestionText,
} from './MultipleChoiceReviewQuestion';
import ReviewFormButtons from './ReviewFormButtons';
import { RATING_MAPPINGS } from '../../../shared/mapping/review';
import ReviewRating from './ReviewRating';

type BasicReviewTypeProps = {
  successDeal: (isViewMode?: boolean) => void;
  companyId: number;
  companyName: string;
  cancelRating: () => void;
  setReload: (reload: number) => void;
  reload: number;
  setReviewStep: (reviewStep: ReviewStep) => void;
  setCurrentReviewId: (reviewId: number) => void;
  currentReviewId: number | null;
  currentReview: BasicReview;
  setRfp: (rfp: boolean) => void;
  rfp: boolean;
  isViewMode: boolean;
  isAddCompany?: boolean;
};

const StyledHeader = styled(H5)`
  margin: auto;
  text-align: center;
  margin-bottom: var(--spacing-lg);
`;

interface RatingValues {
  transparencyRating: string;
  clarityRating: string;
  understandingRating: string;
  responsivenessRating: string;
}

const BasicReviewForm = (props: BasicReviewTypeProps) => {
  const {
    successDeal,
    companyId,
    companyName,
    cancelRating,
    setReviewStep,
    setCurrentReviewId,
    currentReviewId,
    currentReview,
    setRfp,
    setReload,
    reload,
    isViewMode,
    isAddCompany,
  } = props;

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<BasicReview>(currentReview ?? ({} as BasicReview));

  const ratingValues = useMemo(
    () => ({
      transparencyRating:
        currentReview?.ratings?.find(
          (rating) => rating.type === RatingAspect.TRANSPARENCY
        )?.rating || '',
      clarityRating:
        currentReview?.ratings?.find(
          (rating) => rating.type === RatingAspect.CLARITY
        )?.rating || '',
      responsivenessRating:
        currentReview?.ratings?.find(
          (rating) => rating.type === RatingAspect.RESPONSIVENESS
        )?.rating || '',
      understandingRating:
        currentReview?.ratings?.find(
          (rating) => rating.type === RatingAspect.UNDERSTANDING
        )?.rating || '',
    }),
    [currentReview]
  );

  const [ratingFormFields, setRatingFormFields] =
    useState<RatingValues>(ratingValues);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [newCompany, setNewCompany] = useState({
    companyName: '',
    stockTicker: '',
    isApproved: false,
  });
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleRatingChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setRatingFormFields({ ...ratingFormFields, [name]: value });
  };

  let buttonText;
  switch (true) {
    case currentReviewId !== null:
      buttonText = 'Purchase Details';
      break;
    case isViewMode && currentReview.reviewDepth === ReviewDepth.BASIC:
      buttonText = 'Done';
      break;
    case isViewMode:
      buttonText = 'View Purchase Details';
      break;
    default:
      buttonText = 'Make it a Deep Dive';
      break;
  }

  const validate = () => {
    const requiredFields = [
      'dealArea',
      'dealType',
      'dealSize',
      'dealLength',
      'dealClose',
      'commentBuyerBetter',
      'commentBuyerWell',
      'commentWinLose',
      'commentAdvice',
    ];

    const ratingFields = [
      { name: 'transparencyRating', type: RatingAspect.TRANSPARENCY },
      { name: 'clarityRating', type: RatingAspect.CLARITY },
      { name: 'understandingRating', type: RatingAspect.UNDERSTANDING },
      { name: 'responsivenessRating', type: RatingAspect.RESPONSIVENESS },
    ];

    const errors: any = {};

    requiredFields.forEach((field) => {
      //@ts-ignore
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    ratingFields.forEach(({ name, type }) => {
      if (
        //@ts-ignore
        !ratingFormFields?.[name] &&
        !currentReview.ratings?.find((rating) => rating.type === type)?.rating
      ) {
        errors[name] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const submitBasicReviewType = async (
    e: React.FormEvent<HTMLFormElement>,
    continueReview?: boolean
  ) => {
    e.preventDefault();

    const hasErrors = validate();

    if (hasErrors) return;

    setLoading(true);

    let createdCompany;

    if (isAddCompany) {
      const createdCompanyResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/company`,
        newCompany,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      createdCompany = createdCompanyResponse.data;
    }

    const reviewInput = {
      ...formFields,
      reviewType: 'OWNED',
      reviewDepth: currentReview.reviewDepth || ReviewDepth.BASIC,
      userId: user?.id,
      companyId: createdCompany?.id || companyId,
      companyName: createdCompany?.companyName || companyName,
      key: ReviewStep.BASIC_REVIEW,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review`,
        reviewInput,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      const ratingsInput = {
        companyId: createdCompany?.id || companyId,
        reviewId: res.data,
        ratings: {
          transparency: ratingFormFields.transparencyRating,
          clarity: ratingFormFields.clarityRating,
          responsiveness: ratingFormFields.responsivenessRating,
          understanding: ratingFormFields.understandingRating,
        },
      };
      if (currentReview && !isViewMode) {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/reviews/ratings`,
          ratingsInput,
          {
            headers: {
              Authorization: user?.idToken,
            },
          }
        );
      }

      if (continueReview) {
        if (currentReview?.reviewDepth === ReviewDepth.BASIC && isViewMode) {
          navigate(`/company-search/${companyName}`, { replace: true });
          successDeal(true);
          return;
        }
        setReviewStep(ReviewStep.PURCHASE_DETAILS);
        setCurrentReviewId(res.data);
        setLoading(false);
        return;
      }
    } catch (error) {
      cancelRating();
    }
    setLoading(false);
    successDeal();
    setReload(reload + 1);
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as BasicReview));
    setLoading(false);
  }, [currentReview]);

  useEffect(() => {
    if (formFields.dealRfp === 'Yes') {
      setRfp(true);
    } else {
      setRfp(false);
    }
  }, [formFields.dealRfp]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <form>
        {!isViewMode && (
          <StyledHeader>
            Thank you for sharing with Kandir! Let's start by collecting the
            basic review information.
          </StyledHeader>
        )}
        {isAddCompany && (
          <>
            {' '}
            <QuestionWrapper>
              <StyledQuestionText>
                What is the name of the the company you are reviewing?
              </StyledQuestionText>
              <div style={{ width: '80%' }}>
                <StyledTextField
                  onChange={(e) =>
                    setNewCompany({
                      ...newCompany,
                      companyName: e.target.value,
                    })
                  }
                  size="small"
                  required
                />
                {errors.economicBuyerName && (
                  <ErrorMessage error={errors.economicBuyerName} />
                )}
              </div>
            </QuestionWrapper>
            <QuestionWrapper>
              <StyledQuestionText>
                What is the NASDAQ Stock Ticker of the the company you are
                reviewing?
              </StyledQuestionText>
              <div style={{ width: '80%' }}>
                <StyledTextField
                  required
                  onChange={(e) =>
                    setNewCompany({
                      ...newCompany,
                      stockTicker: e.target.value,
                    })
                  }
                  size="small"
                />
                {errors.economicBuyerName && (
                  <ErrorMessage error={errors.economicBuyerName} />
                )}
              </div>
            </QuestionWrapper>
          </>
        )}
        {BASIC_REVIEW_FORM_FIELDS.filter(
          (q): q is MultipleChoiceQuestion =>
            q.type === QuestionType.MULTIPLE_CHOICE
        ).map((question) => (
          <MultipleChoiceReviewQuestion
            key={question.name}
            question={question}
            formFields={formFields}
            handleFormFieldChange={(e) =>
              extendFormFields({ [question.name]: e.currentTarget.value })
            }
            errors={errors}
            isViewMode={isViewMode}
            isNarrow={question.options.length > 8}
          />
        ))}
        <Grid container spacing={2}>
          {BASIC_REVIEW_FORM_FIELDS.filter(
            (q) => q.type === QuestionType.TEXT
          ).map((question: Question, index: number) => (
            <Grid item xs={12} sm={6} key={question.name}>
              <StyledText spaceleft={index % 2 !== 0}>
                {question.question}
              </StyledText>
              <Textarea
                style={{ resize: 'none' }}
                name={question.name}
                value={String(
                  formFields[question.name as keyof BasicReview] || ''
                )}
                onChange={(e) =>
                  extendFormFields({ [e.target.name]: e.target.value })
                }
                required
                readOnly={isViewMode}
                minRows={4}
                spaceleft={index % 2 !== 0}
              />
              {errors[question.name] && (
                <ErrorMessage error={errors[question.name]} />
              )}
            </Grid>
          ))}

          <StyledText>
            On a scale of 1 - 5, how would you rate the companies:
          </StyledText>
          <StyledSelectWrapper>
            {RATING_MAPPINGS.map((rating) => {
              return (
                <ReviewRating
                  key={rating.name}
                  handleChange={handleRatingChange}
                  errors={errors}
                  isReadOnly={isViewMode}
                  value={
                    //@ts-ignore
                    ratingFormFields[rating.name] ||
                    currentReview?.ratings?.find(
                      (responseRating) =>
                        responseRating?.type === rating?.responseName
                    )?.rating
                  }
                  label={rating.label}
                  name={rating.name}
                />
              );
            })}
          </StyledSelectWrapper>
        </Grid>

        <ReviewFormButtons
          reviewStep={ReviewStep.BASIC_REVIEW}
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) => {
            submitBasicReviewType(e, true);
          }}
          submitButtonText="Publish Basic Review"
          nextButtonText={buttonText}
          onSubmit={submitBasicReviewType}
          isViewMode={isViewMode}
        />
      </form>
    </Container>
  );
};

export default BasicReviewForm;

import React, { SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Company } from '../../authentication/types/types.auth';
import plural from '../../../shared/utils/Pluralise';
import { P } from '../../../shared/styles';

type CompanyCardProps = {
  company: Company;
};

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0;
  margin-bottom: 8px;
  color: #333;
  font-weight: 600;
  font-size: 22px;
`;

const Card = styled(motion.div)`
  cursor: pointer;
  background-color: var(--color-white);
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  }
`;

const Logo = styled.img`
  width: 75px;
  height: 75px;
  margin-bottom: 16px;
  border-radius: 50%;
`;

const CardHeader = styled.div`
  position: relative;
  width: 100%;
`;

const Badge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #dfffe2;
  border: 1px solid #28a745;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  color: var(--color-black);
`;

const CompanyCard = (props: CompanyCardProps) => {
  const { company } = props;

  const navigate = useNavigate();

  const defaultImage = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
    return ((
      ev.target as HTMLImageElement
    ).src = require('../../../assets/images/stock.jpeg'));
  };

  const isNew = dayjs().diff(dayjs(company.createdAt), 'month') < 1;

  return (
    <Card
      onClick={() => navigate(`/company-search/${company.companyName}`)}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      whileHover={{ scale: 1.05 }}
    >
      <CardHeader>
        <Logo
          src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${company.companyName}.jpeg`}
          onError={defaultImage}
          alt={company.companyName}
        />
        {isNew && <Badge>Newly added</Badge>}
      </CardHeader>

      <StyledLink to={`/company-search/${company.companyName}`}>
        {company.companyName}
      </StyledLink>
      <P>
        <strong>{company.reviews?.length}</strong>{' '}
        {plural('insights', company.reviewsCount)}
      </P>
    </Card>
  );
};

export default CompanyCard;

import React, { useContext, useState } from 'react';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import { UserContext } from '../../shared/contexts/UserContext';
import LoadingProgress from '../../shared/components/LoadingProgress';
import { Container } from '../../shared/components/Container';
import StaticTextCard from '../../shared/components/StaticTextCard';
import AdminTabs from './components/AdminTabs';

const AdminPage = () => {
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();

  const { user } = useContext(UserContext);

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  if (!user) {
    return <LoadingProgress />;
  }

  if (!user.isAdmin) {
    return (
      <StaticTextCard
        header="Unauthorized"
        text="You are not authorized to view this page."
        link={false}
      />
    );
  }

  return (
    <Container isWhite>
      <AdminTabs />
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Container>
  );
};

export default AdminPage;

import { Button, Rating } from '@mui/material';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { BasicReview, ReviewDepth } from '../../../shared/domain/review';
import { H2, P, screenMdMin } from '../../../shared/styles';
import ReviewHeader from './ReviewHeader';
import { RATING_MAPPINGS } from '../../../shared/mapping/review';

type CompanyReviewProps = {
  review: BasicReview;
  handleOpenReviewDialog: (reviewId?: number, isEdit?: boolean) => void;
};

const deepDiveImage = require('../../../assets/images/Deep_Dive_Icon.png');

const StyledDeepDiveButton = styled(Button)`
  text-transform: capitalize;
  position: relative;
  border-radius: 24px;
  width: 300px;
  padding: var(--spacing-md);
  height: 105px;
  font-size: 18px;
  color: var(--color-black);
  background-color: #ccdcf8;
  margin-top: var(--spacing-md);
  &:hover {
    background-color: #ccdcf8;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonText = styled.span`
  margin-bottom: 5px;
`;

const ButtonImage = styled.img`
  border-radius: var(--spacing-sm);
  width: 65px;
  height: 50px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const PageWrapper = styled.div`
  flex-direction: column;
`;

const BottomBorder = styled.div`
  border: 1px solid transparent;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
`;

const StyledRatingText = styled(P)`
  margin-top: 4px;
`;

const ColouredText = styled(P)`
  color: #666666;
`;

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
  @media ${screenMdMin} {
    flex-direction: unset;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: var(--spacing-md);
  @media ${screenMdMin} {
    width: 22%;
  }
`;

const StyledCommentText = styled(P)`
  max-width: 100%;
  color: #666666;
`;

const CompanyReview = (props: CompanyReviewProps) => {
  const { review, handleOpenReviewDialog } = props;

  return (
    <PageWrapper>
      <ReviewHeader userId={review.userId || 0} />
      <ComponentWrapper>
        <H2>Review</H2>
        <P style={{ marginTop: 'var(--spacing-md)' }}>
          Date Created: {dayjs(review?.createdAt).format('MM/DD/YYYY')}
        </P>
      </ComponentWrapper>
      <ComponentWrapper>
        {review?.ratings &&
          RATING_MAPPINGS.map((rating) => {
            return (
              <RatingWrapper key={rating.label}>
                <StyledRatingText bold>
                  {rating.shortLabel || rating.label}
                </StyledRatingText>{' '}
                &nbsp;
                <Rating
                  readOnly
                  size="large"
                  value={Number(
                    review.ratings.find(
                      (test: any) => test.type === rating.responseName
                    )?.rating
                  )}
                />
              </RatingWrapper>
            );
          })}
      </ComponentWrapper>
      <ComponentWrapper>
        <RatingWrapper>
          <P bold>What type of deal was it?</P>
          &nbsp;
          <ColouredText>{review?.dealType}</ColouredText>
        </RatingWrapper>
        <RatingWrapper></RatingWrapper>
        <RatingWrapper>
          <P bold>How much was this deal worth?</P>
          &nbsp;
          <ColouredText>{review?.dealSize}</ColouredText>
        </RatingWrapper>

        <RatingWrapper></RatingWrapper>
      </ComponentWrapper>
      <ComponentWrapper>
        <RatingWrapper>
          <P bold>Did the seller win the deal?</P>
          &nbsp;
          <ColouredText>{review?.dealClose}</ColouredText>
        </RatingWrapper>
        <RatingWrapper></RatingWrapper>
        <RatingWrapper>
          <P bold>What part of the organization did the seller sell too?</P>
          &nbsp;
          <ColouredText>{review?.dealArea}</ColouredText>
        </RatingWrapper>

        <RatingWrapper></RatingWrapper>
      </ComponentWrapper>
      <P bold>What did the buyer do well?</P>
      &nbsp;
      <StyledCommentText>{review?.commentBuyerWell}</StyledCommentText>
      &nbsp;
      <P bold>What could the buyer have done better?</P>
      &nbsp;
      <StyledCommentText>{review?.commentBuyerBetter}</StyledCommentText>
      &nbsp;
      <BottomBorder>
        <P bold>What advice do you have for the next seller?</P>
        &nbsp;
        <StyledCommentText>{review?.commentAdvice}</StyledCommentText>
        &nbsp;
      </BottomBorder>
      {review.reviewDepth === ReviewDepth.DEEP_DIVE && (
        <ButtonWrapper>
          <StyledDeepDiveButton
            onClick={() => handleOpenReviewDialog(review.id, true)}
          >
            <ButtonContent>
              <ButtonText>Deep Dive Review Available</ButtonText>
              <ButtonImage src={deepDiveImage} alt="Deep Dive Icon" />
            </ButtonContent>
          </StyledDeepDiveButton>
        </ButtonWrapper>
      )}
    </PageWrapper>
  );
};

export default CompanyReview;

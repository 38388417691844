import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReviewTable from './ReviewTable';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { BasicReview } from '../../../shared/domain/review';
import { ReviewFilter } from '../types/welcome.types';
import { P, screenMdMin } from '../../../shared/styles';

const StyledTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-white);
  border: 1px dashed var(--color-black);
  border-radius: 20px;
  height: 450px;
  padding: var(--spacing-md);
`;

const StyledText = styled(P)`
  font-size: 30px;
  margin-bottom: var(--spacing-md);
`;

const StyledFooterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid #e0e0e0;
  height: 50px;
  @media ${screenMdMin} {
    height: 40px;
  }
`;

const StyledFooterText = styled(P)`
  font-size: 14px;
  padding: var(--spacing-md);
  @media ${screenMdMin} {
    font-size: 20px;
    padding: none;
  }
`;

interface ReviewSectionProps {
  setIsNavOpen: (isOpen: boolean) => void;
  reviews: BasicReview[] | null;
  loading: boolean;
  filterType: ReviewFilter;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({
  setIsNavOpen,
  reviews,
  loading,
  filterType,
}) => {
  const [tableRendered, setTableRendered] = useState(false);

  useEffect(() => {
    setTableRendered(false);
  }, [filterType]);

  const handleTableRendered = () => {
    setTableRendered(true);
  };

  if (loading) {
    return <LoadingProgress />;
  }

  if (!reviews || reviews.length === 0) {
    return (
      <StyledTextBox>
        <StyledText>
          You have not left any reviews or followed any companies yet.
        </StyledText>
        <StyledText>
          Leave a review or follow companies to populate your review feed.
        </StyledText>
      </StyledTextBox>
    );
  }

  return (
    <>
      <ReviewTable
        setIsNavOpen={setIsNavOpen}
        allReviews={reviews}
        filterType={filterType}
        onRendered={handleTableRendered}
        loading={loading}
      />
      {!loading && reviews && reviews.length === 0 && (
        <StyledTextBox>
          <StyledText>
            You have not left any reviews or followed any companies yet.
          </StyledText>
          <StyledText>
            Leave a review or follow companies to populate your review feed.
          </StyledText>
        </StyledTextBox>
      )}
      {!loading && tableRendered && (
        <StyledFooterBox>
          <StyledFooterText>
            Looking for a specific company? Use our{' '}
            <button
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                color: 'inherit',
                textDecoration: 'underline',
                cursor: 'pointer',
                font: 'inherit',
              }}
            >
              Company Search
            </button>{' '}
            above!
          </StyledFooterText>
        </StyledFooterBox>
      )}
    </>
  );
};

export default ReviewSection;

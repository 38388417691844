import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../styles';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const LoaderText = styled.div`
  margin-top: 16px;
  font-size: 18px;
  color: #333;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerLoader = () => {
  return (
    <LoaderContainer>
      <LoaderWrapper>
        <ClipLoader color={Colors.lightBlue} size={50} />
        <LoaderText>Loading, please wait...</LoaderText>
      </LoaderWrapper>
    </LoaderContainer>
  );
};

export default SpinnerLoader;

import { RatingAspect } from '../../domain/review';

export const RATING_MAPPINGS = [
  {
    label: 'Transparency in Decision Making',
    name: 'transparencyRating',
    shortLabel: 'Transparency',
    responseName: RatingAspect.TRANSPARENCY,
  },
  {
    label: 'Clarity of Needs',
    name: 'clarityRating',
    responseName: RatingAspect.CLARITY,
  },
  {
    label: 'Technical Competence',
    name: 'understandingRating',
    responseName: RatingAspect.UNDERSTANDING,
  },
  {
    label: 'Responsiveness',
    name: 'responsivenessRating',
    responseName: RatingAspect.RESPONSIVENESS,
  },
];

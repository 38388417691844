export const COMPANY_SIZE_OPTIONS = [
  {
    name: '500 - 1000',
  },
  {
    name: '1,000 - 2,500',
  },
  {
    name: '2,500-  5,000',
  },
  {
    name: '5,000 - 10,000',
  },
  {
    name: '10,000 - 20,000',
  },
  { name: '20,000 - 50,000' },
  { name: '50,000 - 100,000' },
  { name: '100,000 - 200,000' },
  { name: '200,000 - 500,000' },
];

export enum FilterCompanyEnum {
  ALL = 'all',
  MOST_RECENT = 'most_recent',
  MOST_REVIEWED = 'most_reviewed',
}

import pluralize from 'pluralize';

const plural = (
  word: string,
  number: string | number = 0,
  inclusive = false
) => {
  if (inclusive) {
    return `${number} ${pluralize(word, +number)}`;
  }
  return pluralize(word, +number);
};

export default plural;

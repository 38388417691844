import React from 'react';
import styled from 'styled-components';
import { screenMdMin } from '../styles';

type PillProps = {
  isActive: boolean;
  handleClick: () => void;
  label: string;
};

const StyledPill = styled.div<{ active: boolean }>`
  padding: var(--spacing-sm) var(--spacing-xs);
  border-radius: 16px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin: var(--spacing-xs);
  cursor: pointer;
  min-width: 130px;
  text-align: center;
  background-color: ${(props) => (props.active ? '#3498db' : '#ccc')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#2980b9' : '#bbb')};
  }

  @media ${screenMdMin} {
    font-size: 14px;
    width: 150px;
    padding: var(--spacing-sm);
  }
`;

const Pill = ({ label, isActive, handleClick }: PillProps) => {
  return (
    <StyledPill active={isActive} onClick={handleClick}>
      {label}
    </StyledPill>
  );
};

type PillContainerProps = {
  pills: { id: number; label: string; isActive: boolean }[];
  handleFilterClick: (id: number) => void;
};

const StyledPillWrapper = styled.div`
  display: flex;
  margin: var(--spacing-sm);
`;

const Pills = ({ pills, handleFilterClick }: PillContainerProps) => {
  return (
    <StyledPillWrapper>
      {pills.map((pill) => (
        <Pill
          key={pill.id}
          label={pill.label}
          isActive={pill.isActive}
          handleClick={() => handleFilterClick(pill.id)}
        />
      ))}
    </StyledPillWrapper>
  );
};

export default Pills;
